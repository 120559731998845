import { Input, InputBase, type InputProps } from "./InputBase"
import { hasError } from "./ErrorMessage"

export const TextInput = (props: InputProps) => {
  return (
    <InputBase
      {...props}
      render={renderProps => {
        const { item, value, handleChange, handleBlur, disabled } = renderProps
        return <Input type="text" id={item.name} name={item.name} value={value} onChange={handleChange} onBlur={handleBlur} hasError={hasError(renderProps)} disabled={disabled} autoComplete="off" />
      }}
    />
  )
}
