import { EshopItemView } from "./EshopItemView"
import { EshopCart } from "./EshopCart"
import { type EshopItem } from "common"
import { type BoughtItems } from "common"
import { type BoughtItem } from "common"
import { PureComponent } from "react"
import { PriceSpan } from "@website/components/PriceSpan/PriceSpan"
import Heading from "@website/components/Heading/Heading"
import BorderedHeading from "@website/components/BorderedHeading/BorderedHeading"
import classNames from "classnames"

const eshopClasses = classNames(`-mx-4 bg-gray-50 px-4 py-6 dark:bg-gray-900/40 md:-mx-8 md:px-8 md:py-10 md:pt-6`)

export interface EshopProps {
  items: EshopItem[]
  readOnly: boolean
  allowUnavailable: boolean
  boughtItems: BoughtItems
  boughtItemsUpdated: (items: BoughtItems) => void
}

export interface EshopState {
  boughtItems: BoughtItems
}

export class Eshop extends PureComponent<EshopProps, EshopState> {
  constructor(props: EshopProps) {
    super(props)
    this.state = {
      boughtItems: props.boughtItems
    }
  }

  private removeItem = (item: BoughtItem) => {
    const newItems = this.state.boughtItems.remove(item)
    this.setState({
      boughtItems: newItems
    })
    this.props.boughtItemsUpdated(newItems)
  }

  private addItem = (item: BoughtItem) => {
    const newItems = this.state.boughtItems.add(item)
    this.setState({
      boughtItems: newItems
    })
    this.props.boughtItemsUpdated(newItems)
  }

  render() {
    const { items, readOnly, allowUnavailable } = this.props
    const { boughtItems } = this.state
    if (!items || items.length === 0) {
      return null
    }

    return (
      <div>
        {items.map(item => (
          <EshopItemView
            key={item.id}
            item={item}
            allReadOnly={readOnly}
            allowUnavailable={allowUnavailable}
            onAdd={this.addItem}
            isAlreadyInCart={boughtItems.items.filter(bi => bi.eshopItemId === item.id).length > 0}
          />
        ))}
        <div>{this.renderSummary()}</div>
      </div>
    )
  }

  private renderSummary() {
    const { boughtItems } = this.state
    const { items, readOnly, allowUnavailable } = this.props
    const hasUnavailable = !!items.find(i => !i.available)
    return (
      <div className={eshopClasses}>
        <BorderedHeading marginBottomMobile="small">
          <Heading type="h2" size="h3" brand>
            Košík
          </Heading>
          {boughtItems.items.length > 0 && (
            <div className="flex items-baseline gap-x-2">
              <div>
                <strong>Cena zboží:</strong>
              </div>
              <div>
                <PriceSpan size="smallLarge" price={boughtItems.getPrice(this.props.items)} />
              </div>
            </div>
          )}
        </BorderedHeading>
        {readOnly && <p>Objednané položky nelze měnit. Pokud potřebujete změnu, kontaktujte organizátora závodu.</p>}
        {!readOnly && hasUnavailable && !allowUnavailable && <p>Některé objednané položky jsou nedostupné. Pokud potřebujete změnu, kontaktujte organizátora závodu.</p>}
        {!readOnly && boughtItems.items.length === 0 && <p>Pro objednání doplňkového zboží vložte položky do košíku.</p>}
        {boughtItems.items.length > 0 && <EshopCart allReadOnly={readOnly} allowUnavailable={allowUnavailable} boughtItems={boughtItems} items={items} remove={this.removeItem} />}
      </div>
    )
  }
}
