import { cloneElement, useCallback, useState } from "react"
import { Modal } from "./Modal"

interface Props {
  triggerComponent: any
  children: any
}

export const TriggeredModal: React.FC<Props> = ({ triggerComponent, children }: Props) => {
  const [isOpen, setOpen] = useState(false)
  const handleClose = useCallback(() => setOpen(false), [])
  const handleOpen = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(true)
  }, [])

  return (
    <span
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
      }}
    >
      {cloneElement(triggerComponent, { onClick: handleOpen })}
      <Modal isOpen={isOpen} shouldCloseOnOverlayClick={true} onRequestClose={handleClose}>
        {children({ handleClose: handleClose })}
      </Modal>
    </span>
  )
}
