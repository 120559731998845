export const allCallingCodes = {
  US: "+1",
  RU: "+7",
  CZ: "+420",
  SK: "+421",
  AF: "+93",
  AL: "+355",
  DZ: "+213",
  AD: "+376",
  AO: "+244",
  AQ: "+672",
  AG: "+1",
  AR: "+54",
  AM: "+374",
  AU: "+61",
  AZ: "+994",
  BS: "+1242",
  BH: "+973",
  BD: "+880",
  BB: "+1246",
  BE: "+32",
  BZ: "+501",
  BY: "+375",
  BJ: "+229",
  BT: "+975",
  BO: "+591",
  BA: "+387",
  BW: "+267",
  BR: "+55",
  BN: "+673",
  BG: "+359",
  BF: "+226",
  BI: "+257",
  TD: "+235",
  ME: "+382",
  CN: "+86",
  DK: "+45",
  DM: "+1",
  DO: "+1809",
  DJ: "+253",
  EG: "+20",
  EC: "+593",
  ER: "+291",
  EE: "+372",
  ET: "+251",
  FM: "+691",
  FJ: "+679",
  PH: "+63",
  FI: "+358",
  FR: "+33",
  PF: "+689",
  GA: "+241",
  GM: "+220",
  GH: "+233",
  GD: "+1",
  GE: "+995",
  GT: "+502",
  GG: "+44",
  GN: "+224",
  GW: "+245",
  GY: "+592",
  HT: "+509",
  HN: "+504",
  HK: "+852",
  CL: "+56",
  HR: "+385",
  IN: "+91",
  ID: "+62",
  IQ: "+964",
  IR: "+98",
  IE: "+353",
  IS: "+354",
  IT: "+39",
  IL: "+972",
  JM: "+1876",
  JP: "+81",
  YE: "+967",
  JE: "+44",
  ZA: "+27",
  SS: "+211",
  JO: "+962",
  KH: "+855",
  CM: "+237",
  CA: "+1",
  CV: "+238",
  QA: "+974",
  KZ: "+7",
  KE: "+254",
  KI: "+686",
  CO: "+57",
  KM: "+269",
  CG: "+242",
  CD: "+243",
  KP: "+850",
  KR: "+82",
  CR: "+506",
  CU: "+53",
  KW: "+965",
  CY: "+357",
  KG: "+996",
  LA: "+856",
  LS: "+266",
  LB: "+961",
  LR: "+231",
  LY: "+218",
  LI: "+423",
  LT: "+370",
  LV: "+371",
  LU: "+352",
  MG: "+261",
  HU: "+36",
  MY: "+60",
  MW: "+265",
  MV: "+960",
  ML: "+223",
  MT: "+356",
  MA: "+212",
  MH: "+692",
  MR: "+222",
  MU: "+230",
  MX: "+52",
  MD: "+373",
  MC: "+377",
  MN: "+976",
  MZ: "+258",
  MM: "+95",
  NA: "+264",
  NR: "+674",
  DE: "+49",
  NP: "+977",
  NE: "+227",
  NG: "+234",
  NI: "+505",
  NL: "+31",
  NO: "+47",
  NZ: "+64",
  OM: "+968",
  PK: "+92",
  PW: "+680",
  PS: "+0",
  PA: "+507",
  PG: "+675",
  PY: "+595",
  PE: "+51",
  PN: "+64",
  CI: "+225",
  PL: "+48",
  PR: "+1",
  PT: "+351",
  AT: "+43",
  RE: "+262",
  GQ: "+240",
  RO: "+40",
  RW: "+250",
  GR: "+30",
  SV: "+503",
  WS: "+682",
  SM: "+378",
  SA: "+966",
  SN: "+221",
  XI: "+44",
  MK: "+389",
  MP: "+1",
  SC: "+248",
  SL: "+232",
  SG: "+65",
  SI: "+386",
  SO: "+252",
  AE: "+971",
  RS: "+381",
  LK: "+94",
  CF: "+236",
  SD: "+249",
  SR: "+597",
  LC: "+1",
  KN: "+1",
  ST: "+239",
  SZ: "+268",
  SY: "+963",
  SB: "+677",
  ES: "+34",
  SE: "+46",
  CH: "+41",
  TJ: "+992",
  TZ: "+255",
  TH: "+66",
  TW: "+886",
  TG: "+228",
  TO: "+676",
  TT: "+1868",
  TN: "+216",
  TR: "+90",
  TM: "+993",
  TV: "+688",
  UG: "+256",
  UA: "+380",
  UY: "+598",
  UZ: "+998",
  VU: "+678",
  VA: "+39",
  GB: "+44",
  VE: "+58",
  VN: "+84",
  TL: "+670",
  ZM: "+260",
  EH: "+212",
  ZW: "+263"
} as const

export const allCountryCodesForCallingCodes = Object.keys(allCallingCodes)

export const getCallingCodeByCountryCode = (code: string) => allCallingCodes[code as keyof typeof allCallingCodes]
