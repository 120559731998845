import classNames from "classnames"

const errorClasses = classNames(`mt-1 block text-sm leading-6 text-red-600`)

interface ErrorMessageProps {
  error?: string
  checkbox?: boolean
  touched?: boolean
  enforceTouched?: boolean
  className?: string
}

export function ErrorMessage({ error, checkbox, touched, className, enforceTouched }: ErrorMessageProps) {
  if (!hasError({ error, touched, enforceTouched })) {
    return null
  }
  return <div className={classNames(errorClasses, checkbox && "ml-6", className)}>{error}</div>
}

export function hasError({ touched, enforceTouched, error }: ErrorMessageProps): boolean {
  return Boolean((touched || enforceTouched) && error)
}
