import { type BoughtItems } from "common"
import { type EshopItem } from "common"
import { type BoughtItem } from "common"
import classNames from "classnames"

const listClasses = classNames(`m-0 flex list-none flex-wrap gap-3 p-0`)
const listItemClasses = classNames(`flex items-center self-start rounded border border-brand-secondary bg-white py-1 dark:bg-slate-950`)
const listItemContainerClasses = classNames(`px-3 md:py-1`)
const listItemReadOnlyClasses = classNames(`border-gray-300 dark:border-gray-300/30`)
const listItemRemoveClasses = classNames(`cursor-pointer border-l border-l-brand-secondary px-3 md:py-1`)
const listItemRemoveIconClasses = classNames(
  `inline-block scale-110 text-xl text-gray-900 transition-transform duration-200 hover:scale-150 hover:text-brand-primary dark:text-slate-100 dark:hover:text-brand-secondary`
)

export interface EshopCartProps {
  allowUnavailable: boolean
  allReadOnly: boolean
  boughtItems: BoughtItems
  items: EshopItem[]
  remove: (item: BoughtItem) => void
}

interface ItemProps {
  item: EshopItem
  readOnly: boolean
  remove: () => void
  variantId: number | null
}

const ListItem: React.FC<{ readOnly: boolean }> = ({ readOnly, children }) => <li className={classNames(listItemClasses, readOnly && listItemReadOnlyClasses)}>{children}</li>

const EshopCartItem = (props: ItemProps) => (
  <ListItem readOnly={props.readOnly}>
    <div className={listItemContainerClasses}>
      {props.item.name} {props.variantId && <>: {props.item.variants.find(v => v.id === props.variantId)?.name}</>}{" "}
    </div>
    {!props.readOnly && (
      <div className={listItemRemoveClasses} title="Odebrat z košíku" onClick={props.remove}>
        <div className={listItemRemoveIconClasses}>&times;</div>
      </div>
    )}
  </ListItem>
)

export const EshopCart = (props: EshopCartProps) => (
  <div className={listClasses}>
    {props.boughtItems.items.map((item, index) => {
      const eshopItem = props.items.find(i => i.id === item.eshopItemId)
      if (!eshopItem) {
        return null
      }
      const readOnlyItem = props.allReadOnly || (!props.allowUnavailable && !eshopItem.available)
      return <EshopCartItem key={index} item={eshopItem} variantId={item.variantId} readOnly={readOnlyItem} remove={() => props.remove(item)} />
    })}
  </div>
)
