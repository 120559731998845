import { FormItemType } from "common/form-items"
import { TextInput } from "./inputs/TextInput"
import { NumberInput } from "./inputs/NumberInput"
import { TextAreaInput } from "./inputs/TextAreaInput"
import { EmailInput } from "./inputs/EmailInput"
import { RadioInput } from "./inputs/RadioInput"
import { PrivacyAgreementInput } from "./inputs/PrivacyAgreementInput"
import { TermsAndConditionsInput } from "./inputs/TermsAndConditionsInput"
import { PromoAgreementInput } from "./inputs/PromoAgreementInput"
import { CheckBoxInput } from "./inputs/CheckBoxInput"
import { GenderInput } from "./inputs/GenderInput"
import { DropDownInput } from "./inputs/DropdownInput"
import { BornInYearInput } from "./inputs/BornInYearInput"
import { RaceRulesAgreementInput } from "./inputs/RaceRulesAgreementInput"
import { type InputProps } from "./inputs/InputBase"
import { type ComponentType } from "react"
import { DurationInput } from "./inputs/DurationInput"
import { CouponInput } from "./inputs/CouponInput"
import { CountryInput } from "./inputs/CountryInput"
import { PhoneNumberInput } from "./inputs/PhoneNumberInput"
import { DateInput } from "./inputs/DateInput"

export const inputConfig: { [index: number]: ComponentType<InputProps> } = {
  [FormItemType.ShortText]: TextInput,
  [FormItemType.Name]: TextInput,
  [FormItemType.TeamName]: TextInput,
  [FormItemType.City]: TextInput,
  [FormItemType.Club]: TextInput,
  [FormItemType.PhoneNumber]: PhoneNumberInput,
  [FormItemType.Coupon]: CouponInput,
  [FormItemType.Number]: NumberInput,
  [FormItemType.LongText]: TextAreaInput,
  [FormItemType.Comment]: TextAreaInput,
  [FormItemType.PrimaryEmail]: EmailInput,
  [FormItemType.Email]: EmailInput,
  [FormItemType.RaceRulesAgreement]: RaceRulesAgreementInput,
  [FormItemType.PrivacyAgreement]: PrivacyAgreementInput,
  [FormItemType.TermsAndConditionsAgreement]: TermsAndConditionsInput,
  [FormItemType.PromoAgreement]: PromoAgreementInput,
  [FormItemType.CheckBox]: CheckBoxInput,
  [FormItemType.Radio]: RadioInput,
  [FormItemType.Gender]: GenderInput,
  [FormItemType.Dropdown]: DropDownInput,
  [FormItemType.BornInYear]: BornInYearInput,
  [FormItemType.Nickname]: TextInput,
  [FormItemType.Duration]: DurationInput,
  [FormItemType.Country]: CountryInput,
  [FormItemType.Date]: DateInput
}
