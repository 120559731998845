import { Input, InputBase, type InputProps } from "./InputBase"
import { hasError } from "./ErrorMessage"
import { type MouseEventHandler, useCallback, useState } from "react"
import { SuccessIcon } from "@website/components/SuccessIcon"
import { PriceSpan } from "@website/components/PriceSpan/PriceSpan"
import { Button } from "@website/components/Button/Button"
import InputGroup from "@website/components/InputGroup/InputGroup"

export const CouponInput = (props: InputProps) => {
  const [loadingCoupon, setLoadingCoupon] = useState(false)
  const loadCoupon = useCallback(async () => {
    setLoadingCoupon(true)
    try {
      await props.services.loadCoupon(props.item)
    } finally {
      setLoadingCoupon(false)
    }
  }, [props.item, props.services])

  const cancelCoupon: MouseEventHandler<Element> = useCallback(
    e => {
      e.preventDefault()
      props.services.cancelCoupon(props.item)
      return false
    },
    [props.item, props.services]
  )
  return (
    <InputBase
      {...props}
      render={renderProps => {
        const { item, value, handleChange, handleBlur, disabled } = renderProps
        return (
          <>
            <InputGroup>
              <Input
                type="text"
                id={item.name}
                name={item.name}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={hasError(renderProps)}
                disabled={disabled || !!props.services.couponPrice}
                autoComplete="off"
                inputGroup
              />
              {!props.services.couponPrice ? (
                <Button loading={loadingCoupon} onClick={loadCoupon} inputGroup disabled={!!props.services.couponPrice || props.disabled}>
                  Uplatnit
                </Button>
              ) : !props.disabled ? (
                <Button type="button" inputGroup style="danger" onClick={e => cancelCoupon(e)}>
                  Zrušit slevu
                </Button>
              ) : null}
            </InputGroup>
            {props.services.couponPrice && (
              <div className="block text-base mt-1">
                <SuccessIcon size={16} /> Sleva <PriceSpan price={props.services.couponPrice} /> odečtena.
              </div>
            )}
          </>
        )
      }}
    />
  )
}
