import { CheckBox } from "../../../components/CheckBox"
import { CheckBoxInputBase, type InputProps } from "./InputBase"
import { routes } from "../../../routes"

export const TermsAndConditionsInput = (props: InputProps) => (
  <CheckBoxInputBase
    {...props}
    render={renderProps => {
      const { item, value, handleChange, disabled } = renderProps

      return (
        <CheckBox
          name={item.name}
          isRequired={true}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          label={
            <>
              Souhlasím s{" "}
              <a href={routes.terms.getLink()} target="_blank" rel="noopener noreferrer">
                všeobecnými podmínkami
              </a>{" "}
              služby naZavody.cz
            </>
          }
        />
      )
    }}
  />
)
