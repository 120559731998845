import { CheckBox } from "../../../components/CheckBox"
import { CheckBoxInputBase, type InputProps } from "./InputBase"

export const CheckBoxInput = (props: InputProps) => (
  <CheckBoxInputBase
    {...props}
    render={renderProps => {
      const { item, value, handleChange, disabled } = renderProps

      return <CheckBox name={item.name} isRequired={item.isrequired} value={value} onChange={handleChange} label={item.label} disabled={disabled} />
    }}
  />
)
