import { type FormItem } from "common/form-items"

export const groupFormItems = (items: FormItem[]): FormItemGroup[] =>
  items.reduce((acc: FormItemGroup[], item) => {
    const last = acc[acc.length - 1]
    if (last && last.groupname === item.groupname) {
      last.items.push(item)
    } else {
      acc.push({ groupname: item.groupname, items: [item] })
    }
    return acc
  }, [])

export interface FormItemGroup {
  groupname: string | null
  items: FormItem[]
}
