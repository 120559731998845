import { type InputProps, InputBase } from "./InputBase"
import { components, type InputProps as SelectInputProps } from "react-select"
import { type FormItemWithOptions } from "common/form-items"
import ReactSelect from "./ReactSelect"
import { hasError } from "./ErrorMessage"

export function SelectInput<T, IsMulti extends boolean>(props: SelectInputProps<T, IsMulti>) {
  return <components.Input {...props} autoComplete="off" />
}

export const DropDownInput = (props: InputProps) => (
  <InputBase
    {...props}
    render={renderProps => {
      const { item, value, setFieldValue, setFieldTouched, disabled } = renderProps
      const optiomItems = (item as FormItemWithOptions).options
      const options = optiomItems.map(o => ({ value: o, label: o }))

      const onChangeHandler = (v: any) => {
        if (v) {
          setFieldValue(item.name, v.label)
        }
      }

      return (
        <ReactSelect
          components={{ Input: SelectInput }}
          error={hasError(renderProps)}
          instanceId={item.id}
          isClearable={!item.isrequired}
          isDisabled={disabled}
          name={item.name}
          noOptionsMessage={() => "Vyberte"}
          onBlur={() => setFieldTouched(item.name, true)}
          onChange={onChangeHandler}
          options={options}
          placeholder=""
          value={options.filter(opt => opt.label === String(value))}
        />
      )
    }}
  />
)
