import { CheckBox } from "../../../components/CheckBox"
import { CheckBoxInputBase, type InputProps } from "./InputBase"

export const RaceRulesAgreementInput = (props: InputProps) => (
  <CheckBoxInputBase
    {...props}
    render={renderProps => {
      const { config, item, value, handleChange, disabled } = renderProps

      return (
        <CheckBox
          name={item.name}
          isRequired={true}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          label={
            config.raceRulesUrl ? (
              <>
                Souhlasím s{" "}
                <a href={config.raceRulesUrl} target="_blank" rel="noopener noreferrer">
                  pravidly závodu
                </a>
                , na který se registruji.
              </>
            ) : (
              <>Souhlasím s pravidly závodu, na který se registruji.</>
            )
          }
        />
      )
    }}
  />
)
