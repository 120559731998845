import { formatPrice, type Price } from "common/prices"
import classNames from "classnames"

const priceSpanBigClasses = classNames(`text-3xl`)
const priceSpanBigSmallMobileClasses = classNames(`text-xl md:text-3xl`)

export interface PriceSpanProps {
  price: Price
  zeroAsFree?: boolean
  classnames?: string
  size?: "small" | "large" | "smallLarge"
}

export const PriceSpan = (props: PriceSpanProps) => (
  <span
    className={classNames(props.classnames, props.size === "large" && priceSpanBigClasses, props.size === "smallLarge" && priceSpanBigSmallMobileClasses)}
    dangerouslySetInnerHTML={{ __html: formatPrice(props.price, props.zeroAsFree) }}
  />
)
