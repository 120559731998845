import { type Validation, SimpleValidation, CompositeValidation } from "./validation"
import isEmpty from "validator/lib/isEmpty"
import isEmail from "validator/lib/isEmail"
import isInt from "validator/lib/isInt"
import isBoolean from "validator/lib/isBoolean"
import isIn from "validator/lib/isIn"
import isLength from "validator/lib/isLength"
import { type FormItem, type FormItemWithOptions } from "common/form-items"
import { isBooleanType, FormItemType } from "common/form-items"
import { getMaxBornInYear, getMinBornInYear, type BornInYearsConfig } from "./bornInYearsUtils"

export class ValidationFactory {
  create(formItem: FormItem, bornInYearConfig: BornInYearsConfig): Validation {
    const validations: SimpleValidation[] = [
      ...this.getTextValidation(formItem),
      ...this.getRequiredFieldValidations(formItem),
      ...this.getTypeSpecificValidations(formItem),
      ...this.getMaxLengthValidations(formItem, bornInYearConfig),
      ...this.getCustomValidations(formItem)
    ]
    const allValidations = new CompositeValidation(validations)
    return allValidations
  }

  private getRequiredFieldValidations(formItem: FormItem): SimpleValidation[] {
    if (!formItem.isrequired) {
      return []
    }

    if (isBooleanType(formItem.type)) {
      return [new SimpleValidation(val => isBoolean(val) && val.toLowerCase() === "true", "Položka musí být zaškrtnutá.")]
    }

    if (formItem.type === FormItemType.Duration) {
      return [new SimpleValidation(val => !isEmpty(val) && val !== "00:00:00", "Vyplňte tuto položku.")]
    }

    switch (formItem.type) {
      case FormItemType.Dropdown:
      case FormItemType.Radio:
      case FormItemType.Gender:
      case FormItemType.Country:
        return [new SimpleValidation(val => !isEmpty(val), "Vyberte jednu z možností.")]
      default:
        return [new SimpleValidation(val => !isEmpty(val), "Vyplňte tuto položku.")]
    }
  }

  private getTextValidation(_formItem: FormItem): SimpleValidation[] {
    return []

    // const regex = /^[\p{L}\p{N} .,_\-\(\)&+:/?]+$/gu
    // switch (formItem.type) {
    //   case FormItemType.City:
    //   case FormItemType.Club:
    //   case FormItemType.Country:
    //   case FormItemType.LongText:
    //   case FormItemType.Name:
    //   case FormItemType.Nickname:
    //   case FormItemType.ShortText:
    //   case FormItemType.TeamName:
    //     return [new SimpleValidation(val => isEmpty(val) || regex.test(val), "Pro text použijte pouze bězné znaky.")]
    //   default:
    //     return []
    // }
  }

  private getTypeSpecificValidations(formItem: FormItem): SimpleValidation[] {
    if (isBooleanType(formItem.type)) {
      return [new SimpleValidation(val => isBoolean(val || "false"), "Hodnota nemá správný formát.")]
    }

    switch (formItem.type) {
      case FormItemType.Email:
      case FormItemType.PrimaryEmail:
        return [new SimpleValidation(val => isEmpty(val) || isEmail(val), "Zadejte email ve správném formátu.")]
      case FormItemType.Number:
        return [new SimpleValidation(val => isEmpty(val) || isInt(val), "Zadejte číslo.")]
      case FormItemType.BornInYear:
        return [new SimpleValidation(val => isEmpty(val) || isInt(val), "Zadejte rok narození.")]
      case FormItemType.Dropdown:
      case FormItemType.Radio:
        return [new SimpleValidation(val => isEmpty(val) || isIn(val, (formItem as FormItemWithOptions).options), "Vyberte jednu hodnotu z daných možností.")]
      case FormItemType.PhoneNumber:
        return [new SimpleValidation(val => isEmpty(val) || (isInt(val) && isLength(val, { max: 15 }) && isLength(val, { min: 8 })), "Zadejte telefonní číslo ve správném formátu.")]
      default:
        return []
    }
  }

  private getMaxLengthValidations(formItem: FormItem, bornInYearConfig: BornInYearsConfig): SimpleValidation[] {
    switch (formItem.type) {
      case FormItemType.Email:
      case FormItemType.PrimaryEmail:
        return [new SimpleValidation(val => isLength(val, { max: 254 }), "Emailová adresa je příliš dlouhá, maximální délka je 254 znaků.")]
      case FormItemType.ShortText:
      case FormItemType.Name:
      case FormItemType.Nickname:
      case FormItemType.PhoneNumber:
      case FormItemType.City:
      case FormItemType.Coupon:
      case FormItemType.TeamName:
      case FormItemType.Club:
        return [new SimpleValidation(val => isLength(val, { max: 50 }), "Maximální délka je 50 znaků.")]
      case FormItemType.LongText:
      case FormItemType.Comment:
        return [new SimpleValidation(val => isLength(val, { max: 500 }), "Maximální délka je 500 znaků.")]
      case FormItemType.BornInYear:
        return [
          new SimpleValidation(
            val => isInt(val, { min: getMinBornInYear(bornInYearConfig), max: getMaxBornInYear(bornInYearConfig) }),
            `Rok narození musí být mezi lety ${getMinBornInYear(bornInYearConfig)} - ${getMaxBornInYear(bornInYearConfig)}.`
          )
        ]
      default:
        return []
    }
  }

  private getCustomValidations(formItem: FormItem): SimpleValidation[] {
    const validations: SimpleValidation[] = []
    for (const validationName of formItem.validation.split(",")) {
      if (validationName.toLowerCase() === "positivenumber") {
        validations.push(new SimpleValidation(val => isInt(val, { gt: 0 }), "Zadejte kladné číslo."))
      }
    }
    return validations
  }
}
