import classNames from "classnames"
import Label from "./Label"

const checkboxInputClasses = classNames(
  `relative h-4 w-4 cursor-pointer appearance-none rounded-none border border-solid border-gray-300 bg-transparent outline-none after:absolute after:inset-[2px] after:content-[""] after:checked:bg-brand-primary focus:ring-2 focus:ring-brand-primary focus-visible:ring-2 focus-visible:ring-brand-primary dark:after:checked:bg-brand-dark-primary dark:prose-a:text-white`
)
const checkboxInputWrapperClasses = classNames(`flex h-6 items-center`)
const checkboxWrapperClasses = classNames(`inline-flex flex-wrap items-start`)

interface Props {
  name: string
  isRequired: boolean
  label: React.ReactNode
  value?: boolean
  disabled?: boolean
  onChange: (e: React.ChangeEvent<any>) => void
}

export const CheckBox = (props: Props) => {
  return (
    <div className={checkboxWrapperClasses}>
      <div className={checkboxInputWrapperClasses}>
        <input
          className={checkboxInputClasses}
          type="checkbox"
          value={String(props.value)}
          id={props.name}
          name={props.name}
          checked={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      </div>
      <div className="flex-1">
        <Label htmlFor={props.name} isRequired={props.isRequired} marginBottom="none" paddingLeft>
          {props.label}
        </Label>
      </div>
    </div>
  )
}
