import { type FormItem } from "common/form-items"
import { type WrapperProps } from "./FormItemWrapper"
import classNames from "classnames"

const fieldsetClasses = classNames(`rounded border border-gray-300 p-4 dark:border-gray-300/30`)
const legendClasses = classNames(`bg-transparent px-4`)

export interface FormItemGroup {
  groupname: string | null
  items: FormItem[]
}

export interface FormItemGroupWrapperProps extends WrapperProps {
  group: FormItemGroup
}

export const FormItemGroupWrapper = (props: FormItemGroupWrapperProps) => {
  const ItemWrapper = props.components.itemWrapper
  const renderItems = (items: FormItem[]) => items.map(item => <ItemWrapper key={item.name} item={item} {...props} />)

  if (props.group.groupname) {
    return (
      <fieldset className={fieldsetClasses}>
        <legend className={legendClasses}>{props.group.groupname}</legend>
        <div className="grid gap-y-4">{renderItems(props.group.items)}</div>
      </fieldset>
    )
  }
  return <>{renderItems(props.group.items)}</>
}
