import { type SubraceLimitsWithMetadata } from "common"

export interface BornInYearsConfig {
  limits: SubraceLimitsWithMetadata
  enforcedByOrganizer: boolean
}

export const getMaxBornInYear = (config: BornInYearsConfig) => {
  if (config.limits.minage && !config.enforcedByOrganizer) {
    return new Date(config.limits.racedate).getFullYear() - config.limits.minage
  }
  return new Date(config.limits.racedate).getFullYear() - 1
}

export const getMinBornInYear = (config: BornInYearsConfig) => {
  if (config.limits.maxage && !config.enforcedByOrganizer) {
    return new Date(config.limits.racedate).getFullYear() - config.limits.maxage - 1
  }
  return getMaxBornInYear(config) - 100
}

interface BornInYearItem {
  year: number
  outOfRange: boolean
}

export const getBornInYears = (config: BornInYearsConfig): BornInYearItem[] => {
  const limitedMin = getMinBornInYear({ ...config, enforcedByOrganizer: false })
  const limitedMax = getMaxBornInYear({ ...config, enforcedByOrganizer: false })

  if (!config.enforcedByOrganizer) {
    return Array.from({ length: limitedMax - limitedMin }, (v, k) => String(limitedMax - k)).map(year => ({ year: Number(year), outOfRange: false }))
  }

  const unlimitedMin = getMinBornInYear({ ...config, enforcedByOrganizer: true })
  const unlimitedMax = getMaxBornInYear({ ...config, enforcedByOrganizer: true })

  return Array.from({ length: unlimitedMax - unlimitedMin }, (v, k) => String(unlimitedMax - k)).map(yearString => {
    const year = Number(yearString)
    const outOfRange = year < limitedMin || year > limitedMax
    return { year, outOfRange: outOfRange }
  })
}
