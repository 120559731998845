import { CheckBoxInputBase, type InputProps } from "./InputBase"
import { type FormItemWithOptions } from "common/form-items"
import { RadioButton } from "../../../components/RadioButton"
import Label from "../../../components/Label"
import classNames from "classnames"

const radioWrapperClasses = classNames(`flex flex-wrap gap-x-6 gap-y-2`)

export const RadioInput = (props: InputProps) => (
  <CheckBoxInputBase
    checkbox={false}
    {...props}
    render={renderProps => {
      const { item, value, handleChange, disabled } = renderProps
      const radioItems = (item as FormItemWithOptions).options
      return (
        <>
          <Label isRequired={item.isrequired} htmlFor={item.name}>
            {item.label}
          </Label>
          <div className={radioWrapperClasses} id={item.name}>
            {radioItems.map((radioItem, i) => (
              <RadioButton
                id={item.name + i}
                key={item.id + radioItem}
                label={radioItem}
                isRequired={item.isrequired}
                name={item.name}
                value={radioItem}
                checked={radioItem === value}
                onChange={handleChange}
                disabled={disabled}
              />
            ))}
          </div>
        </>
      )
    }}
  />
)
