import { type BoughtItem } from "common"
import { type EshopItem, type EshopItemVariant } from "common"
import { cloudinarySrcSet } from "@website/components/cloudinarySrcSet"
import { PureComponent } from "react"
import { PriceSpan } from "@website/components/PriceSpan/PriceSpan"
import Heading from "@website/components/Heading/Heading"
import { Button } from "@website/components/Button/Button"
import classNames from "classnames"
import ReactSelect from "./inputs/ReactSelect"
import { proseClasses } from "@website/components/Styles/prose"

const eshopItemClasses = classNames(`mb-10 border-t border-gray-200 py-10 first:mb-0 first:border-t-0 first:pt-0 dark:border-gray-200/30`)
const gridClasses = classNames(`grid gap-y-6 lg:grid-cols-2 lg:gap-x-16`)
const gridSimpleItemClasses = classNames(`grid gap-y-6 md:gap-x-16 lg:grid-cols-2`)

const responsiveImageClasses = classNames(`max-h-52 lg:max-h-max`)

export interface Props {
  item: EshopItem
  allReadOnly: boolean
  allowUnavailable: boolean
  isAlreadyInCart: boolean
  onAdd: (boughtItem: BoughtItem) => void
}

export interface State {
  selectedVaríantId: number | null
  addedShown: boolean
}

export class EshopItemView extends PureComponent<Props, State> {
  private shownTimer: any = null

  constructor(props: Props) {
    super(props)
    this.state = {
      selectedVaríantId: null,
      addedShown: false
    }
  }

  componentWillUnmount() {
    this.clearTimer()
  }

  render() {
    const { item, allReadOnly, allowUnavailable } = this.props
    const readOnlyItem = allReadOnly || (!allowUnavailable && !item.available)
    return (
      <div className={eshopItemClasses}>
        {item.imagepublicid && item.imageurl ? this.renderItemWithImage(item, readOnlyItem, item.imagepublicid, item.imageurl) : this.renderItem(item, readOnlyItem)}
      </div>
    )
  }

  private renderItemWithImage(item: EshopItem, readOnly: boolean, publicId: string, url: string) {
    return (
      <div>
        <div className={gridClasses}>
          <div>
            <a href={url} className="inline-block rounded-xl overflow-hidden" target="_blank" rel="noopener noreferrer nofollow">
              <img
                loading="lazy"
                className={responsiveImageClasses}
                {...cloudinarySrcSet(publicId, { rules: [{ breakPoint: "base", imageWidth: 500 }], default: "base", transforms: { quality: 85, crop: "fit", height: 250 } })}
              />
            </a>
          </div>
          <div>
            <div>
              <Heading type="h3" marginBottom>
                {item.name} {!item.available && <>(nedostupné)</>}
              </Heading>
              {item.description ? <div className={proseClasses} dangerouslySetInnerHTML={{ __html: item.description }} /> : null}
            </div>
            {this.renderVariantSelect(item, readOnly)}
            <div className="mt-6 md:mt-4 flex">{this.renderPriceWithAddButton(item, readOnly)}</div>
          </div>
        </div>
      </div>
    )
  }

  private renderItem(item: EshopItem, readOnly: boolean) {
    return (
      <div className={gridSimpleItemClasses}>
        <div>
          <Heading type="h3" marginBottom>
            {item.name}
          </Heading>
          {item.description ? <div className={proseClasses} dangerouslySetInnerHTML={{ __html: item.description }} /> : null}
          {this.renderVariantSelect(item, readOnly)}
        </div>
        <div className="md:self-end">{this.renderPriceWithAddButton(item, readOnly)}</div>
      </div>
    )
  }

  private renderVariantSelect(item: EshopItem, readOnly: boolean) {
    if (!item.variants || item.variants.length === 0) {
      return null
    }
    const variantToSelectOption = (variant: EshopItemVariant) => ({ value: variant.id, label: variant.name })
    const currentValue = this.state.selectedVaríantId ? item.variants.find(v => v.id == this.state.selectedVaríantId) : undefined
    return (
      <div>
        {!readOnly && (
          <div className="max-w-xs mt-4">
            <ReactSelect
              instanceId={"eshop" + item.id}
              onChange={this.variantChanged}
              options={item.variants.filter(v => v.available).map(variantToSelectOption)}
              value={currentValue ? variantToSelectOption(currentValue) : undefined}
              isClearable={false}
              placeholder="Vyberte variantu"
            />
          </div>
        )}
      </div>
    )
  }

  private renderPriceWithAddButton(item: EshopItem, readOnly: boolean) {
    const disabled = item.variants && item.variants.length > 0 && !this.state.selectedVaríantId
    return (
      <div>
        {item.onlyonce && this.props.isAlreadyInCart ? (
          <strong className="text-brand-primary dark:text-brand-dark-primary">Objednáno</strong>
        ) : (
          <div>
            <strong>Cena:</strong>
            <div className="flex flex-wrap items-center gap-y-2 gap-x-4 md:gap-x-5">
              <div>
                <PriceSpan size="large" price={item.price} />
              </div>
              {!readOnly && (
                <Button disabled={disabled} onClick={this.add}>
                  {this.renderButtonTitle()}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }

  private renderButtonTitle() {
    if (this.state.addedShown) {
      return "Přidáno"
    }
    if (this.props.isAlreadyInCart) {
      return "Přidat další"
    }
    return "Vložit do košíku"
  }

  private variantChanged = (newVariant: any) => {
    if (!newVariant?.value) {
      return
    }
    this.setState({ selectedVaríantId: newVariant.value })
  }

  private add = (e: React.MouseEvent) => {
    this.props.onAdd({ eshopItemId: this.props.item.id, variantId: this.state.selectedVaríantId })
    this.setShownTimer(1000)
    this.setState({ addedShown: true })
    e.preventDefault()
  }

  private setShownTimer(delay: number) {
    this.clearTimer()
    this.shownTimer = setTimeout(() => {
      this.setState({ addedShown: false })
      this.shownTimer = null
    }, delay)
  }

  private clearTimer() {
    if (this.shownTimer) {
      clearTimeout(this.shownTimer)
    }
  }
}
