import { CheckBox } from "../../../components/CheckBox"
import { CheckBoxInputBase, type InputProps } from "./InputBase"
import { InfoModal } from "../../../components/Modal/InfoModal"
import { routes } from "../../../routes"
import { proseClasses } from "@website/components/Styles/prose"

export const PrivacyAgreementInput = (props: InputProps) => (
  <CheckBoxInputBase
    {...props}
    render={renderProps => {
      const { item, value, handleChange, disabled } = renderProps

      return (
        <CheckBox
          name={item.name}
          isRequired={true}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          label={
            <>
              Souhlasím se{" "}
              <InfoModal triggerComponent={<a href="#">zpracováním osobních údajů</a>}>
                <div className={proseClasses}>
                  <p>Jsem starší 16 let. Registraci mladších závodníků musí dělat jejich zákonný zástupce.</p>
                  <p>
                    Výslovně souhlasím s tím, že společnost naZavody.cz s.r.o. zpracuje mé osobní údaje pro účely evidence účastníků v závodech a evidence výsledků závodů, tj. souhlasím s ukládáním
                    shora vyplněných údajů na nosiče dat, upravováním, vyhledáváním v nich, předáváním třetím osobám, tříděním a likvidací v souladu se zákonem č. 101/2000 Sb. o ochraně osobních údajů
                    a zákona č. 480/2004 Sb. o některých službách informační společnosti, a to až po dobu 4 let.
                  </p>
                  <p>
                    Výslovně souhlasím s tím, že shora vyplněné údaje jako jméno, příjmení, rok narození, pohlaví, e-mail, telefonní číslo, tým nebo město předá společnost naZavody.cz s.r.o.
                    organizátorovi závodu. Beru na vědomí, že organizátor závodu může moje data před dál, například společnosti měřící čas.
                  </p>
                  <p>
                    Výslovně souhlasím s tím, že mé jméno, příjmení, rok narození, pohlaví, tým nebo město, mé výsledky (jednotlivé naměřené časy) bude zveřejněno na webu nazavody.cz. Beru na vědomí,
                    že organizátor může moje data zveřejnit dál např. na svém webu závodu, popřípadě na webu společnosti měřící čas, může být také v tištěné podobě zveřejněno v průběhu závodu apod.
                  </p>
                  <p>
                    Výslovně souhlasím s tím, že mé některé další údaje (např. IP adresa) mohou být použity pro vývoj a opravu chyb služby naZavody.cz. Tyto údaje jsou automaticky mazány po 30 dnech.
                  </p>
                  <p>
                    Souhlas lze kdykoliv odvolat (a to i jen vůči jednomu z výše zmíněných účelů zpracování). Pokud tak chcete učinit,{" "}
                    <a href={routes.contact.getLink()} target="_blank" rel="noopener noreferrer">
                      kontaktujte nás
                    </a>
                    .
                  </p>
                  <p>
                    Máte také právo na smazání veškerých vašich dat. Stejně tak na jejich poskytnutí. Pokud byste chtěli vaše data smazat nebo je získat,{" "}
                    <a href={routes.contact.getLink()} target="_blank" rel="noopener noreferrer">
                      kontaktujte nás
                    </a>
                    .{" "}
                  </p>
                </div>
              </InfoModal>
              .
            </>
          }
        />
      )
    }}
  />
)
