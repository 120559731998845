import { routes } from "@website/routes"
import { Link } from "react-router-dom"
import { type FormSection, formSectionIds } from "common/form-items"

export class FormSections {
  static all: FormSection[] = [
    { id: formSectionIds.basicInfo, title: "Základní informace", ordering: 1, twoColumns: true },
    { id: formSectionIds.team, title: "Tým", ordering: 2, twoColumns: true },
    { id: formSectionIds.eshop, title: "Doplňkový prodej", ordering: 3, twoColumns: false },
    { id: formSectionIds.extraInfo, title: "Dodatečné informace", ordering: 4, twoColumns: true },
    {
      id: formSectionIds.results,
      title: "Výsledky",
      ordering: 5,
      twoColumns: true,
      description: (
        <>
          Výsledky můžete doplnit později{" "}
          <Link target="blank" to={routes.forRacers.getLink()}>
            upravením registrace
          </Link>
          .
        </>
      )
    },
    { id: formSectionIds.agreements, title: "Formality", ordering: 6, twoColumns: false }
  ] as const
}
