import { type RegistrationFormConfig } from "./RegistrationFormConfig"
import { type FormItem } from "common/form-items"
import { inputConfig } from "./inputConfig"
import { type InputProps } from "./inputs/InputBase"
import { type RegistrationFormComponents } from "./RegistrationFormComponents"
import { createElement } from "react"
import { type FormServices } from "./formServices"

export interface WrapperProps {
  config: RegistrationFormConfig
  services: FormServices
  components: RegistrationFormComponents
  enforceTouched: boolean
  getDisabled: (item: FormItem) => boolean
  getValue: (name: string) => any
  getTouched: (name: string) => boolean | undefined
  getError: (name: string) => string | undefined
  handleChange: (e: React.ChangeEvent<any>) => void
  handleBlur: (e: React.ChangeEvent<any>) => void
  setFieldValue: (name: string, value: any) => void
  setFieldTouched: (name: string, touched: boolean) => void
  setRef: (name: string, ref: any) => void
}

export interface FormItemWrapperProps extends WrapperProps {
  item: FormItem
}

export const FormItemWrapper = (props: FormItemWrapperProps) => {
  const inputProps: InputProps = {
    config: props.config,
    services: props.services,
    item: props.item,
    value: props.getValue(props.item.name),
    touched: props.getTouched(props.item.name),
    error: props.getError(props.item.name),
    disabled: props.getDisabled(props.item),
    handleBlur: props.handleBlur,
    handleChange: props.handleChange,
    setFieldTouched: props.setFieldTouched,
    setFieldValue: props.setFieldValue,
    setRef: props.setRef,
    enforceTouched: props.enforceTouched
  }

  const inputType = inputConfig[props.item.type]

  if (!inputType) {
    throw new Error("Uknown input type")
  }

  return createElement(inputType, inputProps)
}
