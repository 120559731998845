import toBoolean from "validator/lib/toBoolean"
import trim from "validator/lib/trim"
import toInt from "validator/lib/toInt"
import { type FormItem } from "common/form-items"
import { type FormValues } from "common/form-items"
import { isBooleanType, FormItemType } from "common/form-items"
import { emailNormalizer } from "./emailNormalizer"

export class ValueCleaner {
  readonly formItems: FormItem[]
  constructor(formItems: FormItem[]) {
    this.formItems = formItems
  }

  static valueToString(value: any): string {
    return value == null ? "" : String(value)
  }

  clean(values: FormValues): any {
    const cleaned: any = {}
    for (const item of this.formItems) {
      const value = values[item.name]
      const stringValue = ValueCleaner.valueToString(value)
      cleaned[item.name] = this.cleanValue(item, stringValue)
    }
    return cleaned
  }

  private cleanValue(formItem: FormItem, value: string): any {
    if (isBooleanType(formItem.type)) {
      return toBoolean(value)
    }

    switch (formItem.type) {
      case FormItemType.ShortText:
      case FormItemType.LongText:
      case FormItemType.Name:
      case FormItemType.PhoneNumber:
      case FormItemType.City:
      case FormItemType.Club:
      case FormItemType.Coupon:
      case FormItemType.TeamName:
      case FormItemType.Comment:
      case FormItemType.Nickname:
        return trim(value)

      case FormItemType.Email:
      case FormItemType.PrimaryEmail:
        return emailNormalizer(value)

      case FormItemType.Number:
      case FormItemType.BornInYear:
        if (!value) {
          return value
        }
        return toInt(value)

      case FormItemType.Radio:
      case FormItemType.Dropdown:
      case FormItemType.Gender:
      case FormItemType.Country:
      case FormItemType.Date:
        return value

      case FormItemType.Duration:
        if (value !== "00:00:00") {
          return value
        }
        return undefined

      default:
        throw new Error("Unsupported Form item type: " + FormItemType[formItem.type])
    }
  }
}
