export class ServerValidationError {
  readonly value: any
  readonly error: string
  constructor(value: any, error: string) {
    this.value = value
    this.error = error
  }
}

export interface ServerValidationErrors {
  [fieldName: string]: ServerValidationError
}

export const globalServerFieldName = "___global___"

export const containsErrors = (errors: ServerValidationErrors): boolean => Object.keys(errors).length > 0

export class ServerValidationErrorsBuilder {
  private errors: ServerValidationErrors = {}

  addError(fieldName: string, error: ServerValidationError): ServerValidationErrorsBuilder {
    this.errors[fieldName] = error
    return this
  }

  add(fieldName: string, value: any, error: string): ServerValidationErrorsBuilder {
    return this.addError(fieldName, new ServerValidationError(value, error))
  }

  addGlobalError(error: string): ServerValidationErrorsBuilder {
    return this.addError(globalServerFieldName, new ServerValidationError(undefined, error))
  }

  build(): ServerValidationErrors {
    return this.errors
  }
}
