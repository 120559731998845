import { Button } from "../Button/Button"
import { TriggeredModal } from "./TriggeredModal"

interface Props {
  triggerComponent: any
  children: any
}

export const InfoModal = (props: Props) => (
  <TriggeredModal triggerComponent={props.triggerComponent}>
    {(childrenProps: { handleClose: () => void }) => (
      <div>
        <div>
          {props.children}
          <div className="mt-4">
            <Button onClick={childrenProps.handleClose}>
              <div>Zavřít</div>
            </Button>
          </div>
        </div>
        <div className="ReactModal__Close" onClick={childrenProps.handleClose} />
      </div>
    )}
  </TriggeredModal>
)
