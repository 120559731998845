import { InputBase, type InputProps } from "./InputBase"
import { hasError as inputHasError } from "./ErrorMessage"
import classNames from "classnames"

const textAreaClasses = classNames(`block w-full rounded-md border px-3 py-2 text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-1 dark:bg-gray-930 dark:text-slate-200 sm:leading-6`)
const textAreaErrorClasses = classNames(`border-red-600 focus:border-red-600 focus:ring-red-600`)
const textAreaNoErrorClasses = classNames(`border-gray-300 focus:border-brand-secondary focus:ring-brand-secondary dark:border-gray-300/30`)

export const TextArea = (props: React.TextareaHTMLAttributes<HTMLTextAreaElement> & { hasError: boolean }) => {
  const { hasError, ...restProps } = props
  return <textarea {...restProps} className={classNames(textAreaClasses, !hasError && textAreaNoErrorClasses, hasError && textAreaErrorClasses)} />
}

export const TextAreaInput = (props: InputProps) => (
  <InputBase
    {...props}
    render={renderProps => {
      const { item, value, handleChange, handleBlur, disabled } = renderProps

      return (
        <TextArea rows={4} id={item.name} name={item.name} value={value} onChange={handleChange} onBlur={handleBlur} hasError={inputHasError(renderProps)} disabled={disabled} autoComplete="off" />
      )
    }}
  />
)
