import classNames from "classnames"
import Label from "./Label"

const radioInputClasses = classNames(
  `relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-solid border-gray-300 bg-transparent outline-none after:absolute after:inset-[2px] after:rounded-full after:content-[""] after:checked:bg-brand-primary focus:ring-2 focus:ring-brand-primary focus-visible:ring-2 focus-visible:ring-brand-primary dark:after:checked:bg-brand-dark-primary`
)
const radioInputWrapperClasses = classNames(`flex h-6 items-center`)
const radioWrapperClasses = classNames(`flex flex-wrap items-start`)

interface Props {
  id: string
  name: string
  isRequired: boolean
  label: React.ReactNode
  value: string
  checked: boolean
  disabled?: boolean
  onChange: (e: React.ChangeEvent<any>) => void
}

export const RadioButton = (props: Props) => {
  return (
    <div className={radioWrapperClasses}>
      <div className={radioInputWrapperClasses}>
        <input className={radioInputClasses} type="radio" value={String(props.value)} id={props.id} name={props.name} checked={props.checked} onChange={props.onChange} disabled={props.disabled} />
      </div>
      <div className="flex-1">
        <Label htmlFor={props.id} isRequired={props.isRequired} paddingLeft>
          {props.label}
        </Label>
      </div>
    </div>
  )
}
