import { type RegistrationFormConfig } from "./RegistrationFormConfig"
import { type FormItem } from "common/form-items"
import { Card } from "@website/components/Card/Card"
import Heading from "@website/components/Heading/Heading"
import BorderedHeading from "@website/components/BorderedHeading/BorderedHeading"
import ArrowUpIcon from "@website/svg/icons/ArrowUpIcon"
import classNames from "classnames"

export interface ErrorsSummaryProps {
  config: RegistrationFormConfig
  errors: any
  globalError?: string
  alreadyUsedEmail?: string
  scrollToFieldByName: (fieldName: string) => void
}

const listClasses = classNames(`grid gap-y-2`)
const listItemClasses = classNames(`flex flex-wrap gap-x-2`)
const listItemLinkClasses = classNames(`flex-1 cursor-pointer font-bold underline decoration-current transition-colors duration-200 ease-in-out hover:decoration-transparent`)

export const ErrorsSummary = (props: ErrorsSummaryProps) => {
  const isValid = Object.keys(props.errors).length === 0
  return (
    <div className="text-red-500">
      {props.alreadyUsedEmail && (
        <Card type="error" padding="large" marginBottom="medium">
          <strong className="text-base md:text-lg">Pozor: Na email {props.alreadyUsedEmail} už registrace existuje, opravdu ji chcete odeslat znovu?</strong>
        </Card>
      )}
      {props.globalError && <div>{props.globalError}</div>}
      {!isValid && (
        <Card type="error" padding="large" marginBottom="medium">
          <BorderedHeading inverted>
            <Heading type="h2" currentColor>
              Nejprve opravte chyby ve formuláři:{" "}
            </Heading>
          </BorderedHeading>
          <ul className={listClasses}>
            {Object.keys(props.errors).map(name => {
              const formItem = getFormItemByName(props.config, name)
              return (
                <li key={name} className={listItemClasses}>
                  <ArrowUpIcon classnames="w-4 h-4 self-center" />
                  <a href={`#scrollto-${name}`} className={listItemLinkClasses}>
                    {formItem.groupname ? `${formItem.groupname} - ` : ""}
                    {formItem.label}: {props.errors[name]}
                  </a>
                </li>
              )
            })}
          </ul>
        </Card>
      )}
    </div>
  )
}

const getFormItemByName = (config: RegistrationFormConfig, fieldName: string): FormItem => {
  const fieldItem = config.formItems.find(fi => fi.name === fieldName)
  if (!fieldItem) {
    throw new Error("Unable to get label by fieldname")
  }
  return fieldItem
}
