import { hasError } from "./ErrorMessage"
import { Input, InputBase, type InputProps } from "./InputBase"
import { SelectInput } from "./DropdownInput"
import { useCallback, useMemo, useState } from "react"
import { allIso2CountryCodes, getCountryName, splitCountryCodesByImportance } from "@infrastructure/countries/countries"
import { allCallingCodes, allCountryCodesForCallingCodes, getCallingCodeByCountryCode } from "@infrastructure/countries/callingCodes"
import ReactSelect from "./ReactSelect"
import classNames from "classnames"

type PhonePrefixOption = { value: string; code: string; label: string }

const parseWholePhoneNumber = (phoneNumber: string | undefined) => {
  const sanitizedPhoneNumber = phoneNumber ?? ""
  const foundCallingCode = Object.values(allCallingCodes).find(callingCode => sanitizedPhoneNumber.startsWith(callingCode))
  return {
    callingCode: foundCallingCode ?? getCallingCodeByCountryCode("CZ"),
    phoneNumber: sanitizedPhoneNumber.substring(Math.min(foundCallingCode?.length ?? 0, sanitizedPhoneNumber.length))
  }
}

const formatWholePhoneNumber = (callingCode: string | undefined, phoneNumber: string | undefined) => {
  if (!callingCode || !phoneNumber) {
    return undefined
  }
  return `${callingCode}${phoneNumber}`
}

const phoneNumberInputWrapperClasses = classNames(`grid grid-cols-[125px_1fr] gap-x-4`)

export const PhoneNumberInput = (props: InputProps) => {
  const parsedNumber = parseWholePhoneNumber(props.value)
  const [callingCode, setCallingCode] = useState(parsedNumber.callingCode)
  const [phoneNumber, setPhoneNumber] = useState(parsedNumber.phoneNumber)

  const onCallingCodeChangeHandler = useCallback(
    (v: any) => {
      setCallingCode(v.value ?? "")
      props.setFieldValue(props.item.name, formatWholePhoneNumber(v.value, phoneNumber))
    },
    [phoneNumber, props]
  )

  const onPhoneNumberChangeHandler = useCallback(
    (e: React.ChangeEvent<any>) => {
      const inputValue = String(e.target.value) ?? ""
      setPhoneNumber(inputValue)
      props.setFieldValue(props.item.name, formatWholePhoneNumber(callingCode, inputValue))
    },
    [callingCode, props]
  )

  const options = useMemo(() => {
    const mapCodeToOption = (code: string) => ({ code: code, value: getCallingCodeByCountryCode(code), label: `${getCountryName(code, "cs")} (${code}): ${getCallingCodeByCountryCode(code)}` })
    const countryCodes = splitCountryCodesByImportance(allIso2CountryCodes.filter(code => allCountryCodesForCallingCodes.includes(code)))

    return [
      { label: "", options: countryCodes.important.map(mapCodeToOption) },
      { label: "-", options: countryCodes.other.map(mapCodeToOption) }
    ]
  }, [])

  return (
    <InputBase
      {...props}
      render={renderProps => {
        const { item, setFieldTouched, disabled } = renderProps
        return (
          <div className={phoneNumberInputWrapperClasses}>
            <ReactSelect<PhonePrefixOption>
              instanceId={item.id}
              name={item.name}
              isClearable={false}
              value={options.flatMap(opt => opt.options.filter(o => o.value === String(callingCode)))}
              onBlur={() => setFieldTouched(item.name, true)}
              placeholder="Vyberte předvolbu"
              noOptionsMessage={() => "Vyberte předvolbu"}
              options={options}
              onChange={onCallingCodeChangeHandler}
              isDisabled={disabled}
              error={hasError(renderProps)}
              formatOptionLabel={(opt: any, meta: any) => {
                if (meta.context === "value") {
                  return `${opt.code}: ${getCallingCodeByCountryCode(opt.code)}`
                }

                return opt.label
              }}
              components={{ Input: SelectInput }}
            />
            <Input
              type="tel"
              id={item.name}
              name={item.name}
              value={phoneNumber}
              onChange={onPhoneNumberChangeHandler}
              onBlur={renderProps.handleBlur}
              hasError={hasError(renderProps)}
              disabled={disabled}
              autoComplete="off"
            />
          </div>
        )
      }}
    />
  )
}
