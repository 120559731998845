import Label from "../../../components/Label"
import { ErrorMessage } from "./ErrorMessage"
import { Description } from "./Description"
import { type RegistrationFormConfig } from "../RegistrationFormConfig"
import { type FormItem } from "common/form-items"
import { forwardRef } from "react"
import { type FormServices } from "../formServices"
import classNames from "classnames"

interface InputWrapperProps {
  children: React.ReactNode
  name: string
  setRef: (name: string, ref: any) => void
}
export const InputWrapper = (props: InputWrapperProps) => (
  <div id={`scrollto-${props.name}`} ref={r => props.setRef(props.name, r)} className="scroll-mt-16 md:scroll-mt-0">
    {props.children}
  </div>
)

const inputClasses = classNames(
  `block w-full rounded-md border border-solid py-2 pl-3 text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-1 dark:text-slate-200 dark:[color-scheme:dark] sm:leading-6`
)
const inputBgClasses = classNames(`bg-white dark:bg-gray-930`)
const inputBgDisabledClasses = classNames(`cursor-not-allowed bg-gray-100 dark:bg-gray-800`)
const inputErrorClasses = classNames(`border-red-600 focus:border-red-600 focus:ring-red-600`)
const inputGroupClasses = classNames(`flex-1 rounded-r-none`)
const inputNoErrorClasses = classNames(
  `border-gray-300 focus:border-brand-primary focus:ring-brand-primary dark:border-gray-300/30 dark:focus:border-brand-dark-primary dark:focus:ring-brand-dark-primary`
)

export interface InputProps {
  config: RegistrationFormConfig
  disabled?: boolean
  error: string | undefined
  handleBlur: (e: React.ChangeEvent<any>) => void
  handleChange: (e: React.ChangeEvent<any>) => void
  checkbox?: boolean
  item: FormItem
  readonly?: boolean
  services: FormServices
  setFieldTouched: (name: string, touched: boolean) => void
  setFieldValue: (name: string, value: any) => void
  setRef: (name: string, ref: any) => void
  touched: boolean | undefined
  value: any
  enforceTouched: boolean
}

export const Input = (props: React.InputHTMLAttributes<HTMLInputElement> & { hasError: boolean; inputGroup?: boolean }) => {
  const { hasError, inputGroup, disabled, ...restProps } = props

  return (
    <input
      {...restProps}
      disabled={disabled}
      className={classNames(
        inputClasses,
        !hasError && inputNoErrorClasses,
        hasError && inputErrorClasses,
        inputGroup && inputGroupClasses,
        props.type === "search" ? "pr-11" : "pr-3",
        disabled ? inputBgDisabledClasses : inputBgClasses
      )}
    />
  )
}

interface InputBaseProps extends InputProps {
  render: (renderProps: InputProps) => React.ReactNode
}

export const InputBase = forwardRef((props: InputBaseProps, _ref: React.Ref<any>) => {
  const { item, touched, error } = props
  return (
    <InputWrapper name={item.name} setRef={props.setRef}>
      <Label isRequired={item.isrequired} htmlFor={item.name}>
        {item.label}
      </Label>
      {props.render(props)}
      {error && <ErrorMessage touched={touched} error={error} enforceTouched={props.enforceTouched} />}
      <Description text={item.description} />
    </InputWrapper>
  )
})

export const CheckBoxInputBase = forwardRef((props: InputBaseProps, _ref: React.Ref<any>) => {
  const { item, touched, error, checkbox = true } = props

  return (
    <InputWrapper name={item.name} setRef={props.setRef}>
      {props.render(props)}
      <ErrorMessage checkbox={checkbox} touched={touched} error={error} enforceTouched={props.enforceTouched} />
      <Description checkbox={checkbox} text={item.description} />
    </InputWrapper>
  )
})
