import { useState } from "react"
import Select, { components, type Props as SelectProps, type InputProps as SelectInputProps } from "react-select"
import classNames from "classnames"

const controlClasses = {
  base: classNames(`rounded-md border border-solid bg-white text-slate-900 hover:cursor-pointer dark:bg-gray-930 dark:text-slate-200 sm:leading-6`),
  focus: classNames(`border-brand-primary outline-none ring-1 ring-brand-primary dark:border-brand-dark-primary dark:ring-brand-dark-primary`),
  nonFocus: classNames(`border-gray-300 dark:border-gray-300/30`),
  error: classNames(`border-red-600 focus:border-red-600 focus:ring-red-600`),
  focusError: classNames(`border-red-600 ring-1 ring-red-600`)
}
const placeholderClasses = classNames(`pl-1 text-gray-500`)
const selectInputClasses = classNames(`py-2 pl-1`)
const valueContainerClasses = classNames(`gap-1 px-2`)
const singleValueClasses = classNames(`ml-1`)
const multiValueClasses = classNames(`items-center gap-1.5 rounded-md bg-gray-100 pr-1`)
const multiValueLabelClasses = classNames(`leading-6`)
const multiValueRemoveClasses = classNames(`rounded-md border border-gray-200 bg-white text-gray-500 hover:border-red-300 hover:bg-red-50 hover:text-red-800`)
const clearIndicatorClasses = classNames(`rounded-md p-1 text-gray-500 hover:bg-red-50 hover:text-red-800`)
const indicatorSeparatorClasses = classNames(`bg-gray-300 dark:bg-gray-300/20`)
const dropdownIndicatorClasses = classNames(`p-2 text-gray-300 dark:text-gray-300/30`)
const menuClasses = classNames(`mt-2 min-w-[15.625rem] rounded-md border border-solid border-gray-300 bg-white p-1 dark:border-gray-300/30 dark:bg-gray-930 dark:text-slate-200`)
const groupHeadingClasses = classNames(`mb-1 ml-3 mt-2 text-sm text-gray-500`)
const optionClasses = {
  base: classNames(`rounded-md p-2 hover:cursor-pointer`),
  focus: classNames(`bg-brand-primary text-brand-text-primary active:bg-brand-primary dark:bg-brand-dark-primary dark:text-brand-dark-text-primary dark:active:bg-brand-dark-primary`),
  selected: classNames(`bg-brand-secondary text-brand-text-primary dark:bg-brand-dark-secondary dark:text-brand-dark-text-primary`)
}
const noOptionsMessageClasses = classNames(`rounded-md border border-dashed border-gray-200 bg-gray-50 p-2 text-gray-500`)

type ReactSelectProps<T> = SelectProps<T> & {
  error?: boolean
}

export function ReactSelectInput<T, IsMulti extends boolean>(props: SelectInputProps<T, IsMulti>) {
  return <components.Input {...props} autoComplete="off" />
}

const ReactSelect = <T,>(props: ReactSelectProps<T>) => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <Select
      components={{ Input: ReactSelectInput }}
      {...props}
      unstyled
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      className={classNames({ "z-20": isFocused })}
      classNames={{
        clearIndicator: () => clearIndicatorClasses,
        control: ({ isFocused }) =>
          classNames(controlClasses.base, isFocused ? (props.error ? controlClasses.focusError : controlClasses.focus) : props.error ? controlClasses.error : controlClasses.nonFocus),
        dropdownIndicator: () => dropdownIndicatorClasses,
        groupHeading: () => groupHeadingClasses,
        indicatorSeparator: () => indicatorSeparatorClasses,
        input: () => selectInputClasses,
        menu: () => menuClasses,
        multiValue: () => multiValueClasses,
        multiValueLabel: () => multiValueLabelClasses,
        multiValueRemove: () => multiValueRemoveClasses,
        noOptionsMessage: () => noOptionsMessageClasses,
        option: ({ isFocused, isSelected }) => classNames(isFocused && optionClasses.focus, isSelected && optionClasses.selected, optionClasses.base),
        placeholder: () => placeholderClasses,
        singleValue: () => singleValueClasses,
        valueContainer: () => valueContainerClasses
      }}
    />
  )
}

export default ReactSelect
