import { type RegistrationFormConfig } from "./RegistrationFormConfig"

import { type Price } from "common/prices"
import { Card } from "@website/components/Card/Card"
import Heading from "@website/components/Heading/Heading"
import { PriceSpan } from "@website/components/PriceSpan/PriceSpan"
import { Button } from "@website/components/Button/Button"
import classNames from "classnames"

const priceClasses = classNames(`flex flex-wrap items-center gap-x-4 gap-y-2 md:gap-x-5`)
const submitRowClasses = classNames(`flex flex-wrap items-center justify-between gap-x-4 gap-y-2 text-brand-text-primary dark:text-brand-dark-text-primary`)
const submitRowWrapperClasses = classNames(`z-30 sm:sticky sm:bottom-0`)

export interface SubmitRowProps {
  config: RegistrationFormConfig
  totalPrice: Price
  submitting: boolean
  alreadyUsedEmail?: string
  demoModeUsedUp?: boolean
  submitButtonClick: () => void
}

export const SubmitRow = (props: SubmitRowProps) => {
  return (
    <div className={submitRowWrapperClasses}>
      <Card padding="mediumLarge" type="brand">
        <div className={submitRowClasses}>
          {props.config.showTotalPrice ? (
            <Heading type="h2" currentColor>
              Cena celkem:
            </Heading>
          ) : (
            <div></div>
          )}
          <div className={priceClasses}>
            {props.config.showTotalPrice && <PriceSpan price={props.totalPrice} size="large" />}
            <Button
              type="submit"
              style={props.alreadyUsedEmail ? "dangerInverted" : "inverted"}
              onClick={() => props.submitButtonClick()}
              disabled={props.submitting || props.demoModeUsedUp}
              loading={props.submitting}
            >
              {!props.alreadyUsedEmail ? props.config.submitButtonTitle : props.config.submitButtonTitleWithExistingEmail}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  )
}
