import { InputBase, type InputProps } from "./InputBase"
import { SelectInput } from "./DropdownInput"
import { useMemo } from "react"
import { allIso2CountryCodes, getCountryName, splitCountryCodesByImportance } from "@infrastructure/countries/countries"
import ReactSelect from "./ReactSelect"
import { hasError } from "./ErrorMessage"

export const CountryInput = (props: InputProps) => {
  const options = useMemo(() => {
    const mapCodeToOption = (code: string) => ({ value: code, label: `${getCountryName(code, "cs")} (${code})` })
    const countryCodes = splitCountryCodesByImportance(allIso2CountryCodes)
    return [
      { label: "", options: countryCodes.important.map(mapCodeToOption) },
      { label: "-", options: countryCodes.other.map(mapCodeToOption) }
    ]
  }, [])

  return (
    <InputBase
      {...props}
      render={renderProps => {
        const { item, value, setFieldValue, setFieldTouched, disabled } = renderProps

        return (
          <ReactSelect
            instanceId={item.id}
            name={item.name}
            isClearable={!item.isrequired}
            value={options.flatMap(opt => opt.options.filter(o => o.value === String(value)))}
            onBlur={() => setFieldTouched(item.name, true)}
            placeholder=""
            noOptionsMessage={() => "Vyberte stát z nabídky"}
            options={options}
            onChange={(v: any) => {
              if (v) {
                setFieldValue(item.name, v.value)
              }
            }}
            isDisabled={disabled}
            components={{ Input: SelectInput }}
            error={hasError(renderProps)}
          />
        )
      }}
    />
  )
}
