import { CheckBoxInputBase, type InputProps } from "./InputBase"
import { RadioButton } from "../../../components/RadioButton"
import { Gender, GenderDescription } from "common/form-items"
import Label from "../../../components/Label"
import classNames from "classnames"

const radioWrapperClasses = classNames(`flex flex-wrap gap-x-6 gap-y-2`)

export const GenderInput = (props: InputProps) => (
  <CheckBoxInputBase
    checkbox={false}
    {...props}
    render={renderProps => {
      const { item, value, handleChange, disabled } = renderProps
      const sexRadioItems = [Gender.Male, Gender.Female].map(e => ({ value: e, name: GenderDescription[e] }))
      return (
        <>
          <Label isRequired={item.isrequired} htmlFor={item.name}>
            {item.label}
          </Label>
          <div className={radioWrapperClasses} id={item.name}>
            {sexRadioItems.map((radioItem, i) => (
              <RadioButton
                id={item.name + i}
                key={item.id + radioItem.value}
                label={radioItem.name}
                isRequired={item.isrequired}
                name={item.name}
                value={radioItem.value}
                checked={radioItem.value === value}
                onChange={handleChange}
                disabled={disabled}
              />
            ))}
          </div>
        </>
      )
    }}
  />
)
