import { type FormItemGroupWrapperProps, FormItemGroupWrapper } from "./FormItemGroupWrapper"
import { type FormItemWrapperProps, FormItemWrapper } from "./FormItemWrapper"
import { type FormItemSectionWrapperProps, FormItemSectionWrapper } from "./FormItemSectionWrapper"
import { type ErrorsSummaryProps, ErrorsSummary } from "./ErrorsSummary"
import { type SubmitRowProps, SubmitRow } from "./SubmitRow"
import { type ComponentType } from "react"

export interface RegistrationFormComponents {
  itemWrapper: ComponentType<FormItemWrapperProps>
  groupWrapper: ComponentType<FormItemGroupWrapperProps>
  sectionWrapper: ComponentType<FormItemSectionWrapperProps>
  errorsSummary: ComponentType<ErrorsSummaryProps>
  submitRow: ComponentType<SubmitRowProps>
}

export const defaultRegistrationFormCompoments: RegistrationFormComponents = {
  itemWrapper: FormItemWrapper,
  groupWrapper: FormItemGroupWrapper,
  sectionWrapper: FormItemSectionWrapper,
  errorsSummary: ErrorsSummary,
  submitRow: SubmitRow
}
