import countries from "i18n-iso-countries"
import czechCountriesLocale from "i18n-iso-countries/langs/cs.json"

countries.registerLocale(czechCountriesLocale)

export const allIso2CountryCodes = Object.keys(countries.getAlpha2Codes())

export const getCountryName = (iso2code: string, lang: "cs" | "en"): string => {
  const name = countries.getName(iso2code, lang)
  if (name) {
    return name
  }
  throw new Error(`Country name not found for ${iso2code}`)
}

export const splitCountryCodesByImportance = (iso2Codes: string[]) => {
  const importantCodesInOrder = ["CZ", "SK", "DE", "PL", "AT"]
  const otherCodes = iso2Codes.filter(c => !importantCodesInOrder.includes(c))

  return {
    important: importantCodesInOrder,
    other: otherCodes
  }
}
