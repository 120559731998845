import { Input, InputBase, type InputProps } from "./InputBase"
import { hasError } from "./ErrorMessage"
import TimeField from "react-simple-timefield"

export const DurationInput = (props: InputProps) => (
  <InputBase
    {...props}
    render={renderProps => {
      const { item, value, handleChange, handleBlur, disabled } = renderProps
      return (
        <TimeField
          value={value}
          onChange={handleChange}
          showSeconds
          input={<Input type="text" inputMode="numeric" id={item.name} name={item.name} value={value} onBlur={handleBlur} hasError={hasError(renderProps)} disabled={disabled} />}
        />
      )
    }}
  />
)
