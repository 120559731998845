import { CheckBox } from "../../../components/CheckBox"
import { CheckBoxInputBase, type InputProps } from "./InputBase"

export const PromoAgreementInput = (props: InputProps) => (
  <CheckBoxInputBase
    {...props}
    render={renderProps => {
      const { item, value, handleChange, disabled } = renderProps

      return (
        <CheckBox
          name={item.name}
          isRequired={false}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          label="Souhlasím se zasíláním informací o podobných sportovních závodech a akcích, případě se zasíláním dalších obchodních sděleních."
        />
      )
    }}
  />
)
