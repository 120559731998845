import classNames from "classnames"
import { proseClasses } from "./Styles/prose"

interface LabelProps {
  htmlFor?: string
  children?: any
  isRequired: boolean
  marginBottom?: "none" | "small"
  paddingLeft?: boolean
  className?: string
}

const labelClasses = classNames(`mb-1 inline-block leading-6 text-gray-900 dark:text-slate-200`)

export default function Label({ isRequired, children, htmlFor, paddingLeft, marginBottom, className }: LabelProps) {
  return (
    <label htmlFor={htmlFor} className={classNames(labelClasses, marginBottom === "small" && "mb-2", paddingLeft && "pl-2", className)}>
      <span className={proseClasses}>
        {children}
        {!isRequired && <em> – nepovinné</em>}
      </span>
    </label>
  )
}
