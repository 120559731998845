import classNames from "classnames"

const inputGroupClasses = classNames(`flex flex-auto`)

interface InputGroupProps {
  children: React.ReactNode
}

export default function InputGroup({ children }: InputGroupProps) {
  return <div className={inputGroupClasses}>{children}</div>
}
