import { Formik, type FormikProps, type FormikHelpers } from "formik"
import { PureComponent } from "react"
import { type BoughtItems } from "common"
import { type FormItem } from "common/form-items"
import { type Price, zeroPrice, type CouponValue } from "common/prices"
import { Eshop } from "./Eshop"
import { FormSections } from "./formSectionDefinition"
import { type FormSection, formSectionIds } from "common/form-items"
import { type RegistrationFormConfig } from "./RegistrationFormConfig"
import { type ServerValidationErrors, globalServerFieldName } from "@services/registrations/validation/serverValidationError"
import { type Validation } from "@services/registrations/validation/validation"
import { ValidationFactory } from "@services/registrations/validation/validationFactory"
import { ValueCleaner } from "@services/registrations/validation/valueCleaner"
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed"
import { type RegistrationFormComponents } from "./RegistrationFormComponents"
import { type FormServices } from "./formServices"
import webApi from "@website/webApi"
import { ServerCall } from "@infrastructure/api/serverCall"
import { calculateRegistrationPrices } from "common/prices"
import { Card } from "@website/components/Card/Card"
import { groupFormItems } from "./formItemGroup"
import classNames from "classnames"
import { getClientLogger } from "@infrastructure/client-logger/clientLogger"

const gridClasses = classNames(`grid gap-y-10 md:gap-y-16`)

export interface FormProps {
  id?: string
  config: RegistrationFormConfig
  components: RegistrationFormComponents
  validationErrors: ServerValidationErrors
  clearServerValidationError: (fieldName: string) => void
  alreadyUsedEmail?: string
  demoModeUsedUp?: boolean
  submitHandler: (values: any, boughtItems: BoughtItems, actions: FormikHelpers<any>) => Promise<void>
  submitting: boolean
  inCardWrapper?: boolean
}

interface InnerFormProps extends FormikProps<any> {
  eshopCartPrice: Price
  eshop: any
  loadCoupon: (item: FormItem, code: string, setFieldError: (field: string, message: string | undefined) => void) => Promise<void>
  cancelCoupon: (item: FormItem, setFieldValue: (field: string, message: string | undefined) => void, setFieldError: (field: string, message: string | undefined) => void) => void
  couponPrice: Price | undefined
  totalPrice: Price
  beforeSubmit: () => void
  setRef: (name: string, ref: any) => void
  scrollToFieldByName: (fieldName: string) => void
  enforceTouched: boolean
}

class InnerForm extends PureComponent<FormikProps<any> & FormProps & InnerFormProps> {
  constructor(props: FormikProps<any> & FormProps & InnerFormProps) {
    super(props)
    this.state = {
      eshopCartPrice: zeroPrice
    }
  }

  private renderSection = (section: FormSection) => {
    const { config, values, handleChange, handleBlur, setFieldValue, setFieldTouched } = this.props
    const SectionWrapper = this.props.components.sectionWrapper
    const GroupWrapper = this.props.components.groupWrapper
    const visibleFormItems = config.formItems.filter(fi => !fi.hidden)

    const allErrors = this.getAllErrors()
    const allTouched = {
      ...Object.keys(this.props.validationErrors).reduce((acc, field) => ({ ...acc, [field]: true }), {}),
      ...(this.props.touched as any)
    }

    if (section.id === formSectionIds.eshop) {
      return (
        this.props.eshop && (
          <SectionWrapper key={section.id} id={section.id} title={section.title} twoColumns={section.twoColumns} description={section.description}>
            {this.props.eshop}
          </SectionWrapper>
        )
      )
    }
    const groups = groupFormItems(visibleFormItems.filter(fi => fi.sectionid === section.id))
    if (groups.length === 0) {
      return null
    }
    const services: FormServices = {
      loadCoupon: async item => {
        await this.props.loadCoupon(item, this.props.values[item.name], this.props.setFieldError)
      },
      cancelCoupon: item => this.props.cancelCoupon(item, this.props.setFieldValue, this.props.setFieldError),
      couponPrice: this.props.couponPrice
    }

    return (
      <SectionWrapper key={section.id} id={section.id} title={section.title} twoColumns={section.twoColumns} description={section.description}>
        {groups.map(group => (
          <GroupWrapper
            key={group.items.map(i => i.id).toString()}
            config={this.props.config}
            services={services}
            components={this.props.components}
            group={group}
            getDisabled={(i: FormItem) => config.getItemDisabled(i)}
            getValue={(name: string) => values[name]}
            getTouched={(name: string) => Boolean(allTouched[name])}
            getError={(name: string) => (allErrors[name] ? String(allErrors[name]) : undefined)}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            handleBlur={handleBlur}
            setRef={this.props.setRef}
            enforceTouched={this.props.enforceTouched}
          />
        ))}
      </SectionWrapper>
    )
  }

  private getAllErrors = () => {
    const transformedValidationErrors = Object.keys(this.props.validationErrors)
      .filter(field => field !== globalServerFieldName)
      .reduce((acc, field) => ({ ...acc, [field]: this.props.validationErrors[field].error }), {})
    return { ...(this.props.errors as any), ...transformedValidationErrors }
  }

  private renderErrorsSummary = () => {
    const allErrors = this.getAllErrors()
    const globalError = this.props.validationErrors[globalServerFieldName] ? this.props.validationErrors[globalServerFieldName].error : undefined
    const Summary = this.props.components.errorsSummary
    return <Summary config={this.props.config} errors={allErrors} globalError={globalError} scrollToFieldByName={this.props.scrollToFieldByName} alreadyUsedEmail={this.props.alreadyUsedEmail} />
  }

  render() {
    const SubmitRow = this.props.components.submitRow

    return (
      <form onSubmit={this.props.handleSubmit} id={this.props.id}>
        {this.props.inCardWrapper ? (
          <Card padding="large" marginBottom="medium">
            {this.props.config.header}
            <div className={gridClasses}>{FormSections.all.map(section => this.renderSection(section))}</div>
          </Card>
        ) : (
          <>
            {this.props.config.header}
            <div className={gridClasses}>{FormSections.all.map(section => this.renderSection(section))}</div>
          </>
        )}

        {this.renderErrorsSummary()}
        <SubmitRow
          config={this.props.config}
          submitting={this.props.isSubmitting || this.props.submitting}
          totalPrice={this.props.totalPrice}
          alreadyUsedEmail={this.props.alreadyUsedEmail}
          demoModeUsedUp={this.props.demoModeUsedUp}
          submitButtonClick={this.props.beforeSubmit}
        />
      </form>
    )
  }
}

interface RegistrationFormState {
  boughtItems: BoughtItems
  validatingAfterSubmit: boolean
  enforcesTouched: boolean
  couponValue: CouponValue | undefined
}

export class RegistrationForm extends PureComponent<FormProps, RegistrationFormState> {
  private readonly validations: Map<string, Validation>
  private readonly valueCleaner: ValueCleaner
  private fieldRefs: Record<string, HTMLElement> = {}

  constructor(props: FormProps) {
    super(props)
    this.state = {
      boughtItems: this.props.config.boughtItems,
      validatingAfterSubmit: false,
      enforcesTouched: false,
      couponValue: this.props.config.usedCouponValue ?? undefined
    }
    const factory = new ValidationFactory()
    this.validations = new Map()
    this.props.config.formItems.forEach(item => this.validations.set(item.name, factory.create(item, { limits: props.config.limits, enforcedByOrganizer: props.config.madeByOrganizer })))
    this.valueCleaner = new ValueCleaner(this.props.config.formItems)
  }

  private submit = async (values: any, actions: FormikHelpers<any>) => {
    const cleanedValues = this.valueCleaner.clean(values)
    await this.props.submitHandler(cleanedValues, this.state.boughtItems, actions)
    actions.setSubmitting(false)
  }

  private beforeSubmit = () => {
    this.setState({ validatingAfterSubmit: true })
    this.setState({ enforcesTouched: true })
  }

  private validate = (values: any) => {
    const cleanedValues = this.valueCleaner.clean(values)
    const errors: any = {}

    this.props.config.formItems.forEach(item => {
      const validation = this.validations.get(item.name)
      if (validation == null) {
        return
      }
      const value = cleanedValues[item.name]
      const validationErrors = validation.validate(value)

      if (validationErrors.length > 0) {
        errors[item.name] = validationErrors[0].error
      } else if (this.props.validationErrors[item.name] != null && this.props.validationErrors[item.name].value === value) {
        errors[item.name] = this.props.validationErrors[item.name].error
      }
    })

    if (this.state.validatingAfterSubmit) {
      this.scrollToFirstError(errors)
    }

    this.setState({ validatingAfterSubmit: false })
    return errors
  }

  private updateBoughtItems = (items: BoughtItems) => {
    this.setState({
      boughtItems: items
    })
  }

  private loadCoupon = async (item: FormItem, code: string, setFieldError: (field: string, message: string | undefined) => void) => {
    try {
      if (!code?.trim()) {
        this.setState({ couponValue: undefined })
        return
      }

      const couponValue = await ServerCall.callOrThrow<{ subraceId: number; code: string }, CouponValue | null>(webApi.registration.getCoupon, { code, subraceId: this.props.config.subraceId })

      if (couponValue) {
        setFieldError(item.name, undefined)
        this.setState({ couponValue })
        return
      }

      this.setState({ couponValue: undefined })
      setFieldError(item.name, "Slevový kupón není platný")
    } catch (err) {
      this.setState({ couponValue: undefined })
      setFieldError(item.name, "Chyba v načítání kupónu")
      getClientLogger().error("Error in loading coupon", err as Error)
    }
  }

  private cancelCoupon = (item: FormItem, setFieldValue: (field: string, code: string | undefined) => void, setFieldError: (field: string, message: string | undefined) => void) => {
    this.setState({ couponValue: undefined })
    setFieldValue(item.name, "")
    setFieldError(item.name, undefined)
    this.props.clearServerValidationError(item.name)
  }

  private scrollToFirstError = (errors: any) => {
    const minErrorField = Object.keys(errors).reduce(
      (minFormItem: FormItem | undefined, name) => {
        const currentItem = this.props.config.formItems.find(fi => fi.name === name)
        if (!minFormItem) {
          return currentItem
        }
        return minFormItem.ordering < currentItem!.ordering ? minFormItem : currentItem
      },
      undefined as FormItem | undefined
    )

    if (minErrorField) {
      this.scrollToFieldByName(minErrorField.name)
    }
  }

  private scrollToFieldByName = (fieldName: string) => {
    smoothScrollIntoView(this.fieldRefs[fieldName], { behavior: "smooth", block: "start", scrollMode: "if-needed" })
  }

  private setRefByName = (fieldName: string, ref?: HTMLElement) => {
    if (ref) {
      this.fieldRefs[fieldName] = ref
    }
  }

  render() {
    const eshopItems = this.props.config.shopItems
    const eshop =
      eshopItems.length > 0 && this.props.config.eshopMode.enabled ? (
        <Eshop
          items={eshopItems}
          readOnly={this.props.config.eshopMode.readOnly}
          allowUnavailable={this.props.config.eshopMode.allowUnavailable}
          boughtItems={this.props.config.boughtItems}
          boughtItemsUpdated={this.updateBoughtItems}
        />
      ) : null

    const priceInfo = calculateRegistrationPrices(this.props.config.racePrice, eshopItems, this.state.boughtItems, this.state.couponValue ?? null)

    return (
      <div>
        <Formik enableReinitialize={true} initialValues={this.props.config.values} onSubmit={this.submit} validate={this.validate} validateOnChange={true} validateOnBlur={true}>
          {(formikProps: FormikProps<any>) => {
            return (
              <InnerForm
                eshop={eshop}
                eshopCartPrice={priceInfo.eshopprice}
                couponPrice={priceInfo.couponprice ?? undefined}
                totalPrice={priceInfo.totalprice}
                loadCoupon={this.loadCoupon}
                cancelCoupon={this.cancelCoupon}
                setRef={this.setRefByName}
                beforeSubmit={this.beforeSubmit}
                scrollToFieldByName={this.scrollToFieldByName}
                enforceTouched={this.state.enforcesTouched}
                {...formikProps}
                {...this.props}
              />
            )
          }}
        </Formik>
      </div>
    )
  }
}
