interface ArrowUpIconProps {
  classnames?: string
  color?: string
}

export default function ArrowUpIcon({ classnames, color = "currentColor" }: ArrowUpIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke={color} className={`flex-shrink-0 ${classnames}`}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18" />
    </svg>
  )
}
