import Heading from "@website/components/Heading/Heading"
import BorderedHeading from "@website/components/BorderedHeading/BorderedHeading"

export interface FormItemSectionWrapperProps {
  id: number
  title: string
  children: any
  twoColumns: boolean
  description?: React.ReactNode
}

export function FormItemSectionWrapper({ title, children, twoColumns, description }: FormItemSectionWrapperProps) {
  return (
    <section>
      <BorderedHeading sticky marginBottomMobile="small">
        <Heading type="h2" brand>
          {title}
        </Heading>
      </BorderedHeading>
      {description && <p className="mb-4">{description}</p>}
      <div className={`grid md:gap-x-8 lg:gap-x-16 ${twoColumns ? "gap-y-6 md:gap-y-8 md:grid-cols-2" : "md:grid-cols-1 gap-y-3"}`}>{children}</div>
    </section>
  )
}
