import { proseClasses } from "@website/components/Styles/prose"
import classNames from "classnames"

interface Props {
  text?: string
  checkbox?: boolean
}

export const Description = (props: Props) => {
  if (!props.text) {
    return null
  }
  return (
    <div className={classNames(proseClasses, props.checkbox && "ml-6")}>
      <small dangerouslySetInnerHTML={{ __html: props.text }} />
    </div>
  )
}
