export interface Props {
  size: number
  color?: string
}

export const SuccessIcon = (props: Props) => {
  const { color = "#15803d" } = props

  const circleStyles = {
    stroke: color
  }

  const checkMarkStyles = {
    width: `${props.size}px`,
    height: `${props.size}px`,
    "--color": color
  }

  return (
    <svg className="checkmark-animation" style={checkMarkStyles} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" preserveAspectRatio="xMinYMid">
      <circle className="circle-animation" style={circleStyles} cx="26" cy="26" r="25" fill="none" />
      <path className="check-animation" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  )
}
