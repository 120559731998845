import { type FormItem } from "common/form-items"
import { type FormValues } from "common/form-items"
import { isBooleanType } from "common/form-items"

export class FormDefaults {
  static getDefaults(formItems: FormItem[]): any {
    const values: FormValues = {}
    for (const item of formItems) {
      values[item.name] = FormDefaults.getDefaultValue(item)
    }
    return values
  }

  private static getDefaultValue(formItem: FormItem): any {
    if (isBooleanType(formItem.type)) {
      return false
    }

    return ""
  }
}
