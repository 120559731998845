import { ValidationError } from "./validationError"
import { ValueCleaner } from "./valueCleaner"

export interface Validation {
  validate(value: string): ValidationError[]
}

export class SimpleValidation implements Validation {
  private validator: (value: string) => boolean
  private errorMessage: string
  constructor(validator: (value: string) => boolean, errorMessage: string) {
    this.validator = validator
    this.errorMessage = errorMessage
  }

  validate(value: string): ValidationError[] {
    const val = ValueCleaner.valueToString(value)
    if (!this.validator(val)) {
      return [new ValidationError(this.errorMessage)]
    }
    return []
  }
}

export class CompositeValidation implements Validation {
  private validations: SimpleValidation[]
  constructor(validations: SimpleValidation[]) {
    this.validations = validations
  }

  validate(value: string): ValidationError[] {
    const errors: ValidationError[] = []
    for (const validation of this.validations) {
      errors.push(...validation.validate(value))
    }
    return errors
  }
}
