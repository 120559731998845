import { InputBase, type InputProps } from "./InputBase"
import { components, type InputProps as SelectInputProps } from "react-select"
import { subraceLimitsToText } from "common"
import ReactSelect from "./ReactSelect"
import { hasError } from "./ErrorMessage"
import { getBornInYears } from "@services/registrations/validation/bornInYearsUtils"

const YearInput = (props: SelectInputProps<{ value: string; label: string }>) => {
  return <components.Input {...props} {...{ pattern: "[0-9]*", inputMode: "numeric" }} autoComplete="off" />
}

export const BornInYearInput = (props: InputProps) => (
  <InputBase
    {...props}
    item={{ ...props.item, description: `${subraceLimitsToText(props.config.limits) ?? ""}${props.item.description ? ` ${props.item.description}` : ""}` }}
    render={renderProps => {
      const { item, value, setFieldValue, setFieldTouched, disabled } = renderProps
      const options = getBornInYears({ limits: props.config.limits, enforcedByOrganizer: props.config.madeByOrganizer }).map(o => ({
        value: o.year.toString(),
        label: o.outOfRange ? `${o.year} (mimo kategorii)` : o.year.toString()
      }))

      const onChangeHandler = (v: any) => {
        if (v) {
          setFieldValue(item.name, Number(v.value))
        }
      }

      return (
        <ReactSelect
          components={{ Input: YearInput }}
          error={hasError(renderProps)}
          instanceId={item.id}
          isClearable={!item.isrequired}
          isDisabled={disabled}
          name={item.name}
          noOptionsMessage={() => "Vyberte si rok narození z nabídky"}
          onBlur={() => setFieldTouched(item.name, true)}
          onChange={onChangeHandler}
          options={options}
          placeholder=""
          value={options.filter(opt => opt.value === String(value))}
        />
      )
    }}
  />
)
